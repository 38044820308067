<template>
  <v-container fluid>
    <v-row
      no-gutters
      justify="start"
      class="mb-5"
    >
      <v-toolbar
        elevation="1"
        style="border-left-width:thick; border-left-style:solid; border-left-color:coral;"
      >
        <v-btn
          icon
          @click="cancel"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title class="pa-0">
          Edit Site
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
    </v-row>
    <v-row
      no-gutters
      justify="center"
    >
      <v-card width="1900">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container
            v-if="site"
            fluid
            pa-0
          >
            <v-toolbar
              elevation="0"
              color="primary"
              dark
            >
              <v-toolbar-title class="pa-0">
                General
              </v-toolbar-title>
              <v-icon class="ml-2">
                mdi-information-outline
              </v-icon>
            </v-toolbar>
            <v-row
              justify="start"
              class="pa-5"
            >
              <v-col cols="6">
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="nameRules"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="customer"
                  :menu-props="{offsetY:true}"
                  :items="customers"
                  label="Customer Name"
                  item-text="name"
                  return-object
                  readonly
                  filled
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="referenceId"
                  label="Site Reference ID"
                  :rules="referenceIdRules"
                  readonly
                  filled
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="billingAddress"
                  label="Billing Address"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-divider />
            <v-row
              justify="center"
              class="pa-5"
            >
              <v-col cols="12">
                <v-select
                  v-model="dataSourceId"
                  :menu-props="{offsetY:true}"
                  :items="dataSources"
                  label="Type"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="ipAndPort"
                  label="IP & Port"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="clientId"
                  label="Client Id"
                  readonly
                  filled
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="username"
                  label="Username"
                  :rules="usernameRules"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="password"
                  label="Password"
                  :rules="passwordRules"
                  type="password"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-divider />
            <v-row
              justify="center"
              class="pa-5"
            >
              <v-col cols="6">
                <v-select
                  v-model="currency"
                  :menu-props="{offsetY:true}"
                  :items="currencies"
                  label="Currency"
                  item-text="displayName"
                  return-object
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="volume"
                  :menu-props="{offsetY:true}"
                  :items="volumes"
                  label="Volume"
                  item-text="symbol"
                  return-object
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="height"
                  :menu-props="{offsetY:true}"
                  :items="heights"
                  label="Height"
                  item-text="symbol"
                  return-object
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="temperature"
                  :items="temperatures"
                  label="Temperature"
                  item-text="symbol"
                  return-object
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row
              class="ma-2"
              justify="end"
            >
              <v-card-actions class="pa-0">
                <v-btn
                  text
                  @click="cancel"
                >
                  <v-icon left>
                    mdi-close-circle-outline
                  </v-icon>
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="validate"
                >
                  <v-icon left>
                    mdi-check-circle-outline
                  </v-icon>
                  Save
                </v-btn>
              </v-card-actions>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'SiteCardEdit',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      valid: false,
      site: null,
      name: '',
      referenceId: '',
      customer: {},
      billingAddress: '',
      dataSources: [
        'MiSite',
        'Ligo',
        'PPX'
      ],
      dataSourceId: '',
      ipAndPort: '',
      username: '',
      password: '',
      clientId: '',
      currency: {},
      height: {},
      volume: {},
      temperature: {},
      nameRules: [
        v => !!v || 'Name is required.',
        v => (v.length === 0 || v.length >= 3) || 'Name requires at least 3 chars.'
      ],
      referenceIdRules: [
        v => !!v || 'Site Reference ID is required.'
      ],
      usernameRules: [
        v => (v.length === 0 || v.length >= 3) || 'Username requires at least 3 chars.'
      ],
      passwordRules: [
        v => (v.length === 0 || v.length >= 3) || 'Password requires at least 3 chars.'
      ]
    }
  },
  computed: {
    ...mapGetters(['currencies', 'volumes', 'heights', 'temperatures', 'customers'])
  },
  async beforeCreate () {
    await this.$store.dispatch('fetchCustomers')
  },
  created () {
    this.$store.dispatch('getSite', { id: this.id }).then(data => {
      this.site = data
      this.name = data.name
      this.clientId = data.id
      this.customer = this.customers.find(el => el.id === data.customer_id)
      this.referenceId = data.referenceId
      this.billingAddress = data.billingAddress
      this.dataSourceId = data.siteDataSourceId
      this.ipAndPort = data.ipAndPort
      this.username = data.username
      this.password = data.password
      this.currency = this.currencies.find(el => el.code === data.currencyCode)
      this.volume = this.volumes.find(el => el.code === data.volumeCode)
      this.height = this.heights.find(el => el.code === data.heightCode)
      this.temperature = this.temperatures.find(el => el.code === data.temperatureCode)
    })
  },
  methods: {
    cancel () {
      this.$router.go(-1)
    },
    validate () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.$store
        .dispatch('editSite', {
          id: this.id,
          name: this.name,
          referenceId: this.referenceId,
          billingAddress: this.billingAddress,
          siteDataSourceId: this.dataSourceId,
          ipAndPort: this.ipAndPort,
          username: this.username,
          password: this.password,
          currency: this.currency.code,
          volume: this.volume.code,
          height: this.height.code,
          temperature: this.temperature.code
        })
        .then(() => {
          this.$router.go(-1)
        })
    }
  }
}
</script>
